<template>
  <div>
    <img :src="imageSrc" alt="Image" />
    <canvas ref="canvas" width="400" height="400"></canvas>
    <div>
      <input v-model="text" placeholder="Enter text" />
      <button @click="addTextToCanvas">Add Text</button>
      <button @click="convertToBase64">Convert to Base64</button>
    </div>
    <div>
      <textarea
        v-model="base64Data"
        rows="5"
        cols="50"
        placeholder="Base64 Image"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // imageSrc: "your_image_url_here",
      imageSrc:
        "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
      text: "",
      base64Data: "",
    };
  },
  methods: {
    addTextToCanvas() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      const image = new Image();

      image.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        ctx.font = "20px Arial";
        ctx.fillStyle = "white";
        ctx.fillText(this.text, 10, 30);
      };

      image.src = this.imageSrc;
    
    },
    convertToBase64() {
      const canvas = this.$refs.canvas;
      console.log(canvas);
      const imageData = canvas.toDataURL("image/png");
      console.log(imageData);
      // this.base64Data = imageData.replace("data:image/png;base64,", "");
    },
  },
};
</script>
